import React from 'react'
import './certifications.css'
import {FaAward} from 'react-icons/fa'
import {BsFillPatchCheckFill} from 'react-icons/bs'
import AWS from '../../assets/aws.png'


const Certifications = () => {
  return (
    <section id ="certifications">
      <h5>My Noteworthy Achievements</h5>
      <h2>Certifications</h2>

      <div className='container certifications__container'>
        <div className='certifications__content'>
          <div className="certifications__cards">
            <article className='certifications__card'>
              <img src={AWS} alt="aws" />
            </article>
            <article className='certifications__card certifications__card-summary'>
            
              <article className='certifications__summary-details'>
                <BsFillPatchCheckFill className='certifications__summary-icon' />
                <div>
                  <h4>AWS Certified Solutions Architect - Associate | Amazon Web Services (AWS)</h4>
                </div>
              </article>
              <article className='certifications__summary-details'>
                <BsFillPatchCheckFill className='certifications__summary-icon' />
                <div>
                  <h4>JavaScript Algorithms and Data Structures | freeCodeCamp</h4>
                </div>
              </article>
              <article className='certifications__summary-details'>
                <BsFillPatchCheckFill className='certifications__summary-icon' />
                <div>
                  <h4>Responsive Web Design | freeCodeCamp</h4>
                </div>
              </article>
              <article className='certifications__summary-details'>
                <BsFillPatchCheckFill className='certifications__summary-icon' />
                <div>
                  <h4>Scientific Cimputing with Python | freeCodeCamp</h4>
                </div>
              </article>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Certifications